






































































































































































































































































































































































































































































































































import { Component, Vue, Watch, Mixins } from "vue-property-decorator";
import { Notify } from "quasar";
import FormValidator from "@/components/mixins/FormValidator.ts";

interface SelectOption {
  value: string | any;
  label: string;
}

@Component({
  components: {}
})
export default class FormularioInscripcionGas extends Mixins(FormValidator) {
  private win = window;
  private base = window.location;
  private formatedRut = "";
  private data: any = {
    rut: "",
    nombre: "",
    email: "",
    telefono: "",
    direccion: ""
  };
  private bTerminos = false;
  private customModel = "no";
  private aceptaTerminos = false;
  private verRevisar = true;
  private verRegistrado = false;
  private verFormulario = false;
  private verCompletado = false;

  //En caso de cerrar, dejar esta variable como true
  private verCerrado = false;

  @Watch("formatedRut")
  private formatRutVecino(newValue: string) {
    newValue = newValue.replace(/\./g, "").toUpperCase();
    this.data.rut = newValue;
    newValue = newValue.replace(/-/g, "");
    if (newValue.length > 5) {
      const rut = newValue
        .substring(0, newValue.length - 1)
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
      const dv = newValue.substring(newValue.length - 1);
      this.formatedRut = `${rut}-${dv}`;
    } else {
      this.formatedRut = newValue
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")
        .toUpperCase();
    }
  }

  private mounted() {
    document.title = "Inscripción Beneficio Gas- Municipalidad de Quilpué";
  }

  private revisarRut() {
    if (this.data.rut.trim() == "" || !this.validateRut(this.data.rut)) {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese un Rut válido.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    }

    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios
      .post("formulario/gas/validar", this.data)
      .then((res: any) => {
        this.$q.loading.hide();
        this.verRevisar = false;
        this.verRegistrado = false;
        this.verFormulario = true;
        this.verCompletado = false;
        return;
      })
      .catch((err: any) => {
        if (err.response.data.message == "REGISTRADO") {
          this.$q.loading.hide();
          this.verRevisar = false;
          this.verRegistrado = true;
          this.verFormulario = false;
          this.verCompletado = false;
          return;
        } else {
          this.$q.loading.hide();
          Notify.create({
            color: "negative",
            message: "Ha ocurrido un error, por favor intente nuevamente.",
            icon: "report_problem",
            position: "top",
            timeout: 2500
          });
          return;
        }
      });
  }

  private enviar() {
    if (this.data.rut.trim() == "" || !this.validateRut(this.data.rut)) {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese un Rut válido.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.data.nombre.trim() == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese su nombre completo.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.data.email == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese su Correo.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (!this.validEmail(this.data.email)) {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese un correo válido.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    }
    if (this.data.telefono == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese su Teléfono.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.data.telefono.length < 9) {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese un teléfono válido (9 dígitos).",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    }

    if (this.data.direccion.trim() == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese su dirección.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    }

    if (this.customModel == "no" || !this.customModel) {
      Notify.create({
        color: "negative",
        message: "Por favor acepte los términos y condiciones.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    }
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios
      .post("formulario/gas/registro", this.data)
      .then((res: any) => {
        this.$q.loading.hide();
        this.verRevisar = false;
        this.verRegistrado = false;
        this.verFormulario = false;
        this.verCompletado = true;
        return;
      })
      .catch((err: any) => {
        if (err.response.data.message == "REGISTRADO") {
          this.$q.loading.hide();
          this.verRevisar = false;
          this.verRegistrado = true;
          this.verFormulario = false;
          this.verCompletado = false;
          return;
        } else {
          this.$q.loading.hide();
          Notify.create({
            color: "negative",
            message: "Ha ocurrido un error, por favor intente nuevamente.",
            icon: "report_problem",
            position: "top",
            timeout: 2500
          });
          return;
        }
      });
  }

  private revisarOtro() {
    this.formatedRut = "";
    this.data = {
      rut: "",
      nombre: "",
      email: "",
      telefono: "",
      direccion: ""
    };
    this.verRevisar = true;
    this.verRegistrado = false;
    this.verFormulario = false;
    this.verCompletado = false;
  }

  private cerrarTerminos(acepta = null) {
    if (acepta && acepta === true) {
      this.aceptaTerminos = true;
      this.customModel = "si";
      return;
    } else if (acepta === false) {
      this.aceptaTerminos = false;
      this.customModel = "no";
      return;
    } else {
      return;
    }
  }

  private routerGo(name: string) {
    if (name && name != "") {
      if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch(err => {
          console.log(err);
        });
      }
    }
  }

  private validEmail(email: string) {
    // eslint-disable-next-line
    let emailRegex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRegex.test(email) === true) {
      return true;
    } else {
      return false;
    }
  }
  public validateRut(newValue: string) {
    let texto = newValue;
    let tmpstr = "";
    for (let i = 0; i < texto.length; i++)
      if (
        texto.charAt(i) != " " &&
        texto.charAt(i) != "." &&
        texto.charAt(i) != "-"
      )
        tmpstr = tmpstr + texto.charAt(i);
    texto = tmpstr;
    const largo = texto.length;
    if (largo < 2) {
      return false;
    }
    for (let i = 0; i < largo; i++) {
      if (
        texto.charAt(i) != "0" &&
        texto.charAt(i) != "1" &&
        texto.charAt(i) != "2" &&
        texto.charAt(i) != "3" &&
        texto.charAt(i) != "4" &&
        texto.charAt(i) != "5" &&
        texto.charAt(i) != "6" &&
        texto.charAt(i) != "7" &&
        texto.charAt(i) != "8" &&
        texto.charAt(i) != "9" &&
        texto.charAt(i) != "k" &&
        texto.charAt(i) != "K"
      ) {
        return false;
      }
    }
    let invertido = "";
    for (let i = largo - 1, j = 0; i >= 0; i--, j++)
      invertido = invertido + texto.charAt(i);
    let dtexto = "";
    dtexto = dtexto + invertido.charAt(0);
    dtexto = dtexto + "-";
    let cnt = 0;
    for (let i = 1, j = 2; i < largo; i++, j++) {
      //alert("i=[" + i + "] j=[" + j +"]" );
      if (cnt == 3) {
        dtexto = dtexto + ".";
        j++;
        dtexto = dtexto + invertido.charAt(i);
        cnt = 1;
      } else {
        dtexto = dtexto + invertido.charAt(i);
        cnt++;
      }
    }
    invertido = "";
    for (let i = dtexto.length - 1, j = 0; i >= 0; i--, j++)
      invertido = invertido + dtexto.charAt(i);

    if (this.revisarDigito2(texto)) {
      return true;
    }
    return false;
  }
  public revisarDigito(dvr: string) {
    const dv = dvr + "";
    if (
      dv != "0" &&
      dv != "1" &&
      dv != "2" &&
      dv != "3" &&
      dv != "4" &&
      dv != "5" &&
      dv != "6" &&
      dv != "7" &&
      dv != "8" &&
      dv != "9" &&
      dv != "k" &&
      dv != "K"
    ) {
      return false;
    }
    return true;
  }
  public revisarDigito2(crut: string) {
    const largo = crut.length;
    if (largo < 2) {
      return false;
    }
    let rut = "";
    if (largo > 2) rut = crut.substring(0, largo - 1);
    else rut = crut.charAt(0);
    const dv = crut.charAt(largo - 1);
    this.revisarDigito(dv);
    if (rut == null || dv == null) return 0;
    let dvr = "0";
    let suma = 0;
    let mul = 2;
    for (let i = rut.length - 1; i >= 0; i--) {
      suma = suma + parseInt(rut.charAt(i), 10) * mul;
      if (mul == 7) mul = 2;
      else mul++;
    }
    const res = suma % 11;
    if (res == 1) dvr = "k";
    else if (res == 0) dvr = "0";
    else {
      const dvi = 11 - res;
      dvr = dvi + "";
    }
    if (dvr != dv.toLowerCase()) {
      return false;
    }
    return true;
  }
}
